.partner-details-containers{
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    padding: 0px 0 25px 0;
    background-color: #ffffff;
}
.partner-details-title{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e2e;
}

.partner-field-value-container{
    display: grid;
    grid-template-columns:1fr 1fr;
}
