.manage-partner-list-container{
    position: relative;
}
.add-partner-btn-wrapper{
    position: absolute;
    right: 140px;
    top:2px;
    cursor: pointer;
    z-index: 10;
}
@media only screen and (max-width: 767px) {
    .add-partner-btn-wrapper{
        position: absolute;
        left: 0px;
        top:-50px;
    }
}