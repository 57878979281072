.add-product-form-container {
    position: relative !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
}

.add-product-form-item {
    margin: 0;
    justify-self: stretch;
}

.description-box{
    grid-column-start: 1;
    grid-column-end: 3;
}
.add-product-button-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top:30px;
    /* margin-bottom: 20px; */
}