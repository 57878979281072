.user-bank-details-wrapper{
    display: grid;
    grid-template-columns:1fr 1fr;
    margin-bottom: 30px;
}

.btn1{
    width: 200px;
  } 
  .btn2{
    margin: 10px;
    border: 1px solid #594D9D;
    background-color: #fff;
    color: #594D9D;
  width: 120px;
  }
  .btn2:hover{
    background-color: #594D9D;
    color: #fff;
    border: 1px solid #594D9D;
  
  }
  .btn4{
    width: 250px;
  }
  .detailBlocks h1{
    font-size: 34px;font-weight:bold;margin-right: 20px;
  }
  .btn3{
    border: 1px solid #594D9D;padding: 5px 30px;
  }
  .bLeft h2{
    color: #2C235F;
    font-weight: bold;
  font-size: 22px;
  }
  .bLeft h3{ 
  font-size: 20px;
    color: #676767;
  }
  .bLeft{
    margin: 30px 0;
    width: 45%;
  }
  .form-control{
    border: .1px solid #2C235F;
    color: #2C235F;
  }