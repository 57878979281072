.loaneligibilitycheck-sec {
  padding: 20px;
  max-width: 600px;
  border-radius: 6px;
  background-color: #ffffff;
}

.loaneligibilitycheck-header {
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
}

.loaneligibilitycheck-header h2 {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
  margin: 0px;
}

.loaneligibilitycheck-table .table-responsive {
  border-radius: 5px;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  overflow: scroll;
}

.loaneligibilitycheck-table .table-responsive .table {
  margin: 0px;
}

.loaneligibilitycheck-table .table-responsive .table thead tr th {
  font-size: 10px;
  font-weight: 600;
  color: #2e2e2e;
}

.loaneligibilitycheck-table .table-responsive .table tbody tr td {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.loaneligibilitycheck-table .table-responsive .table tbody tr .loaneligibilitycheck-value {
  color: #002a4c;
}

.loaneligibilitycheck-sec .filter-bottom {
  margin-top: 35px !important;
}
