.title {
    font-size: large;
    font-weight: 600;
    color: #2e2e2e;
    padding-bottom: 0.6rem;
}

.chip-card {
    border: 1px solid #448AFA;
    border-radius: 8px;
    padding: 2.25rem;
    top: 10px;
}

.chip-card:hover {
    background-color: #ebe5e5;
}

.card-text{
    font-size: medium;
}