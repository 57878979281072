.common-black-border-btn {
    padding: 11px 20px 10px 22px;
    border-radius: 4px;
    border: solid 1px #002a4c;
    background-color: #ffffff;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #002a4c;
  }
  
  .common-button-margin-left {
    margin-left: 25px;
  }
  .common-button-grey-out{
    padding: 11px 14px 10px 15px;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #ffffff;
    color: #b9b9b9;
  }
  @media only screen and (max-width: 767px) {
    .common-button-margin-left {
      margin-left: 0;
      margin-top:10px;
    }
  }