.addressdetails-sec .filter-header {
  padding: 0;
  margin-top: 14px;
  margin-right: 11px;
  margin-left: 9px;
  margin-bottom: 18px;
}

.addressdetails-sec-btn .black-border-btn {
  padding: 5px 11px;
  border: solid 1px #979797;
  background-color: rgba(0, 42, 76, 0.3);
}

.addressdetails-sec .editaddress-visible-sec {
  border-top: none;
}

.addressdetails-sec-btn .default-chip {
  margin-top: 24px;
  margin-left: 19px
}

.set-default-active{
  text-decoration: none;
   color: #ffffff;
  background-color: #002a4c;
}
.edit-address-form-wrapper{
  display: grid;
  grid-template-columns:1fr 1fr;
  gap:20px;
}
.column-width-double{
  grid-column-start: 1;
  grid-column-end: 3;
}