.onboarding-page {
  display: flex;
  justify-content: space-between;
}
.custom-input input[type='text'], .custom-input input[type='password']{
  height:45px;
  padding : 10px 18px;
  max-width : 550px;
  font-size : 16px;
}
.onboarding-image {
  height: 100vh;
  text-align: center;
  margin: 0 auto;
  flex-basis: 49%;
  position: relative;
}

.onboarding-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.onboarding-content {
  flex-basis: 43%;
  margin-top: 13rem;
}

.onboarding-header label {
  font-size: 14px;
  font-weight: 500;
  color: #737373;
}

.onboarding-header {
  padding-bottom: 0;
}

.onboarding-fields p {
  width: 350px;
  height: 39px;
  font-size: 10px;
  color: #2e2e2e;
  margin-bottom: 2rem !important;
}

.onboarding-fields p label {
  color: #737373;
}

.onboarding-fields {
  width: 350px;
}

.onboarding-header h2 {
  font-size: 26px;
  font-weight: 600;
  color: #2e2e2e;
}

.custom-input label {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px !important;
  color: #737373;
  float: left;
}

.custom-input a {
  font-size: 12px;
  font-weight: 500;
  color: #448AFA;;
  cursor: pointer;
  float: right;
}

.custom-input a:focus {
  outline: none;
}

.custom-input a:hover {
  color: #448AFA;
  text-decoration: none;
}

.custom-input .form-control {
  display: block;
}

.custom-input {
  margin: 8px 0 0 0;
  height: 100px;
}

.blue-button {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #448AFA;
  width: 150px;
  height: 38px;
  background: #448AFA;
  border: 1px solid #448AFA;
  border-radius: 14px;
}

.hide-img {
  position: relative;
  top: -35px;
  left: 315px;
  cursor: pointer;
  width: 25px;
  height: 11px;
}

.hide-img img {
  width: 100%;
  object-fit: cover;
}

.show-img {
  position: relative;
  top: -30px;
  left: 315px;
  cursor: pointer;
  width: 25px;
  height: 11px;
}

.show-img img {
  width: 100%;
  object-fit: cover;
}

img {
  vertical-align: middle;
  border-style: none;
}

.onboarding-btn {
  text-align: center;
}

.onboarding-btn .black-border-btn {
  height: 38px;
  width: 150px;
}

.onboarding-fotter {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #737373;
  margin-top: 2rem;
}

.onboarding-fotter label a {
  color: #002a4c;
  text-decoration: none;
}

/* Validations Page icons  */

@media only screen and (max-width: 767px) {
  .onboarding-content {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 400px) {
  .onboarding-fields {
    width: 100%;
  }

  .onboarding-content {
    margin: 0;
    margin-top: 80px;
  }

  .onboarding-page {
    padding: 0 20px;
  }

  .hide-img {
    position: relative;
    top: -40px;
    left: 315px;
    cursor: pointer;
    width: 25px;
    height: 11px;
  } 

   .show-img {
    position: relative;
    top: -40px;
    left: 315px;
    cursor: pointer;
    width: 25px;
    height: 11px;
  }

  .onboarding-fields p {
    width: 270px;
  }

  .onboarding-header {
    padding-bottom: 4rem;
  }

}
* {
  margin: 0;
  padding: 0;
  font-family: Karla;
}
.test{
  display: flex;
} 
.logo{
    display: block;
  margin: 40vh auto;  
}
.signIN-col {
  width: 50vw;
  height: 100vh;
}
.signUp {
  text-align: center;
  padding-top: 100px;
}
.signUp h1 {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #212b27;
}
.signUp h3 {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */

  text-align: center;

  /* Paragraph */

  color: #32403b;
}
.signUp a{
  color: rgba(0, 0, 0, 0.5);
}
form {
  display: block;
  margin: 50px auto 20px auto;
  width: 80%;
}
input {
  margin: 30px 0 0 0;
  height: 100px;
}
 
.btn {
  padding: 10px 100px 10px 100px;
  background: #448AFA;
  border: 1px solid #448AFA;
  border-radius: 14px;
  color: #fff;

}
.btn:hover{
  background: #fff;
  color: #448AFA;
  border: 1px solid #448AFA;
}
  @media screen and (max-width: 1250px){}
@media screen and (max-width: 900px){}
@media screen and (max-width: 400px){
    #signIn{
        flex-wrap: wrap-reverse;
    }
    .signIN-col{
        width: 100%;
    }
    .left {display: none;}
    .signUp{padding: 50px;}
    .btn{padding:10px 30px;}
    form{width: 100%;}
   
}
