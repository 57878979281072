.header-section {
  position: fixed;
  width: 100%;
  z-index: 999;
  /* border-bottom: 1px solid #f6f6f6 !important; */
  box-shadow: 0px 1px 1px 0px rgba(173, 173, 173, 0.5);
}

.header-section.navbar {
  padding: 0px 30px;
}
.date-time-custom {
  font-weight: bold;
  width: 115px;
}

.navbar.navbar-expand-lg.navbar-light.bg-light {
  background-color: #ffff !important;
  /* border-bottom: 1px solid #f6f6f6 !important; */
}

.dropdown-toggle.basic-nav-dropdown a {
  font-size: medium;
}
.header-menu.navbar-nav a {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #737373;
  padding-left: 30px;
  cursor: pointer;
  text-align: left;
  padding: 10px 90px 0px 20px;
}

a.dropdown-toggle.nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
  margin-right: 0px !important;
  width: 73px !important;
  font-size: medium;
}

.header-user a.dropdown-toggle.nav-link {
  color: transparent !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  top: 46px !important;
}

.header-menu.navbar-nav {
  margin-left: 400px;
}

.header-menu .nav-link {
  margin-right: 35px !important;
}

.header-menu.navbar-nav a.active {
  color: #448afa !important;
  cursor: pointer;
}

.header-menu .mobile-nav {
  display: none;
}

.nav-link {
  padding: 0px !important;
}

/* .navbar-nav a {
  text-align: left;
  padding: 10px 90px 0px 20px;
} */
.dropdown-divider {
  margin: 0px !important;
}
.web-header-nav-drop-down {
  align-items: center;
}
.web-header-nav-drop-down .dropdown-item {
  height: 34px;
  padding: 11px;
  margin-top: 6px;
  font-size: 100px;
}

.web-header-nav-drop-down .dropdown-item:hover {
  color: #737373 !important;
}

.web-header-nav-drop-down .dropdown-item.active,
.web-header-nav-drop-down .dropdown-item:active {
  background-color: #ebebeb !important;
}

.web-header-nav-drop-down .dropdown-item:focus,
.web-header-nav-drop-down .dropdown-item:hover {
  color: #448afa !important;
}

.header-user {
  position: relative;
}

.header-user .nav-item {
  position: absolute;
  z-index: -1;
  opacity: 1;
}

.header-user-img {
  height: 40px;
  width: 40px;
}

.header-user img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.header-user .nav-item {
  height: 45px;
  width: 45px;
  background-color: transparent;
  opacity: 1;
  border-radius: 50%;
  z-index: 99;
  top: 20px;
  position: absolute;
}

.header-user .nav-link {
  text-align: center;
  display: block;
  color: transparent !important;
}

.header-user .dropdown-menu {
  right: 0px !important;
  top: 45px;
  margin: 0 auto;
  left: auto;
  font-size: 20px;
  padding: 0px !important;
  font-weight: 600;
}

.header-section .header-user .dropdown-item:hover {
  color: #448afa !important;
}

.header-user .dropdown-item:active a:hover {
  color: #448afa;
  background-color: #737373;
}

.header-user .dropdown-item {
  height: 40px;
  padding: 11px;
  margin-right: 30px;
  color: #737373 !important;
}

.header-item:hover {
  background-color: #ebebeb !important;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px !important;
}

.header-section .navbar {
  padding: 1.3rem 2.5rem;
}

.header-section .navbar-light .navbar-toggler {
  border-color: transparent;
  padding-right: 0px;
}

.header-section .navbar-toggler-icon {
  width: 2em;
  height: 2em;
}

.profile-img {
  width: 40px;
  height: 40px;
  background-color: #d8d8d8;
  overflow: hidden;
  border-radius: 100%;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: #448afa !important;
}

.web-header-nav-drop-down {
  /* flex-direction: row-reverse; */
  display: flex;
  margin-right: 35px;
}

.web-header-nav-drop-down-img img {
  width: 10px;
  height: 6px;
  vertical-align: baseline;
  margin-left: -16px !important;
}

@media only screen and (max-width: 991px) {
  .header-user {
    display: none;
  }

  .header-menu.navbar-nav {
    margin-left: 0px;
    margin-top: 15px;
  }

  .header-menu .mobile-nav {
    display: inline;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: medium;
    font-size: 16px !important;
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
  }

  .web-header-nav-drop-down {
    flex-direction: row-reverse;
    margin: 0 0 0 0;
  }
  .navbar-nav .dropdown-menu {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-light .navbar-nav .nav-link {
    margin-bottom: 15px !important;
    font-size: medium;
  }

  .navbar {
    padding: 0.5rem 2rem !important;
  }
}
