.downloadcvv-sec {
  border-radius: 4px;
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  padding: 15px 0px 0px;
}

.downloadcvv-sec ul {
  list-style: none;
}

.downloadcvv-sec ul li {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
  /* margin-bottom: 15px; */
  cursor: pointer;
  padding: 15px 15px;
  /* background-color: #2e2e2e; */
}

.downloadcvv-sec ul li a {
  color: #2e2e2e;
  text-decoration: none;
}

.downloadcvv-sec ul li:last-child {
  margin-bottom: 0px;
}

.downloadcvv-sec ul li:hover {
  background-color: #dfdfdf !important;
}
