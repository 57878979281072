/* filter */

.filter-sec {
  /* width: 490px; */
  border-radius: 6px;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
  align-items: center;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.close-filter {
  display: flex;
}

.close-filter h2 {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
  margin-left: 15px !important;
}

.filter-header h2 {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
  margin: 0px;
}

.filter-form-sec {
  padding: 0px 20px;
}

.add-btn {
  min-width: 86px;
  height: auto;
}

.filter-btn {
  min-width: 86px;
  padding: 7px 17px !important;
}

.filter-form {
  display: block;
  justify-content: space-between;
  margin-bottom: 14px;
  align-items: center;
}

.filter-select-value {
  margin-top: -43px;
  width: 100%;
  margin-bottom: 8px;
}

.filter-select-value select.form-control {
  border-radius: 6px !important;
  border: solid 1px #dfdfdf !important;
  background-color: #ffffff !important;
  padding: 11px !important;
  height: 40px !important;
  -webkit-appearance: none;
}

.filter-select-value .form-control {
  font-size: 12px;
  font-weight: 500;
  color: #b9b9b9;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fliter-input-value {
  width: 100%;
  position: relative;
}

.fliter-input-value .form-control {
  height: 42px !important;
  border-radius: 6px !important;
  border: solid 1px #dfdfdf !important;
  background-color: #ffffff !important;
  padding: 11px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 2px;
  color: #000000 !important;
}

.filter-multi-select .item-renderer input[type='checkbox'] {
  display: none !important;
}

.filter-multi-select .select-panel {
  padding: 1.4rem 0;
}

.filter-multi-select input[type='checkbox']+span:before {
  content: '\00a0';
  display: inline-block;
  margin: 0 1.2rem 0 0;
  padding: 0;
  vertical-align: top;

  width: 1.6rem;
  height: 1.6rem;
  border-radius: 2px;
  border: solid 1px #979797;
  background-color: #ffffff;
}

.filter-multi-select input[type='checkbox']:checked+span:before {
  content: '\2713' !important;
  text-align: center;
  border-radius: 2px;
  border: solid 1px #002a4c;
  background-color: #ffffff;
}

.form-control:focus {
  color: #002a4c !important;
}

.filter-input-title {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 12px !important;
}

.fliter-input-close {
  padding-top: 25px;
  cursor: pointer;
}

.fliter-input-value .filter-min-max-val {
  display: flex;
  justify-content: space-between;
}

.fliter-input-value .downarrow-img {
  float: right;
  margin-top: -28px;
  margin-right: 10px;
}

.fliter-input-value .down-arrow-img {
  position: absolute;
  top: 41px;
  right: 10px;
}

.fliter-input-value .filter-min-max-val .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  border: solid 1px #dfdfdf !important;
  background-color: #ffffff !important;
  padding: 11px !important;
  width: 47%;
}

.item-renderer input[type='checkbox'] {
  height: 20px;
  width: 30px;
}

.item-renderer span {
  vertical-align: top !important;
  margin-top: -1px;
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.select-item {
  background-color: #ffffff !important;
}

.filter-bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: solid 1px #ebebeb;
}

.close-filter label {
  cursor: pointer;
}

/* responsive */

@media screen and (max-width: 767px) {
  .filter-header {
    margin-bottom: 0px;
  }

  .filter-form {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .filter-select-value {
    width: 95%;
    margin-bottom: 15px;
  }

  .fliter-input-value {
    width: 95%;
  }

  .filter-sec {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    overflow: auto;
    transition: all ease-in-out 2s;
  }
}

@media screen and (max-width: 498px) {
  .filter-select-value {
    width: 90%;
  }

  .fliter-input-value {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .filter-header h2 {
    font-size: 12px;
  }

  .filter-header .black-border-btn {
    padding: 6px 8px;
    font-size: 12px;
  }
}
