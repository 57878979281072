/* Sign Agreements */
.sign-agreements h1 {
  font-size: 2.4rem;
  font-weight: 600;
  /* padding-bottom: 1rem; */
  color: #2e2e2e;
  margin-bottom: 1.4rem !important;
}

.chip{
  background: #fff;
  border: 2px solid #ebebeb;
  border-radius: 8px;
}

.chip-body img{
  position: absolute;
  right: -6px;
  bottom: -20px;
  width: 121px;
  opacity: 0.05;
}

.chip-body {
  position: relative;
  display: block;
  padding: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.heading{
  font-size: 18px;
  margin-top: 5px;
  color: #303641;
}

.para{
  font-size: 11px;
  margin-top: 5px;
  color: #303641;
}

.num{
  color: #303641;
  font-size: 38px;
  font-weight: bold;
}

.agreements {
  /* width: 523px; */
  /* height: 197px; */
  border-radius: 6px;
  border: solid 1px #d7d9de;
  background-color: #ffffff;
  padding: 1.9rem;
}

.agreements ul li span {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.83;
  color: #2e2e2e;
}

.agreements ul li label {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.83;
  color: #737373;
  width: 180px;
}

.download .black-border-btn {
  padding: 10px 52px;
}

.download .black-border-btn a {
  color:#002a4c;
}
.download .black-border-btn:hover>a {
  color:#ffffff;
  text-decoration: none;
}


.download {
  padding-top: 15px;
}

/* Loan inform tabel */
.loan-inform {
  /* width: 260px; */
  /* height: 734px; */
  background-color: #ffffff;
  margin-bottom: 20px;
  display: -webkit-box;
}

.loan-content {
  font-size: 1.4rem;
  font-weight: 600;
  color: #737373;
  margin: 15px 0px 15px 18px;
}

.loan-content1{
  font-size: 1.4rem;
  font-weight: 600;
}

.loan-content-name {
  text-transform: uppercase;
}

.loan-content label {
  color: #002a4c;
  font-size: x-large;
}

.loan-tabel ul {
  /* margin-left: 1.8rem; */
  padding: 0px 1.8rem;
}

.loan-tabel ul li label {
  font-size: 15px;
  color: #737373;
  width: 127px;
  margin-top: 9px;
}

.loan-tabel ul li span {
  font-size: 15px;
  font-weight: 600;
  color: #2e2e2e;
  width: 100px;
  margin-top: 9px;
}

.loan-tabel ul li {
  margin-bottom: 11px;
  display: flex;
  justify-content: space-between;
}

.loan-tabel {
  border-bottom: solid #d7d9de;
  background-color: #ffffff;
  border-right: solid 1px #d7d9de;
  border-top: solid #d7d9de;
  border-left: solid 1px #d7d9de;
  margin-top: 15px;
}

/* .loan-tabel:nth-child(3n) {
  border-bottom: none;
} */

.add-new-user {
  border-radius: 8px;
  background-color: rgba(0, 163, 255, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.new-user h1 {
  font-size: 12px;
  color: #2e2e2e;
}

.new-user {
  align-self: center;
  width: 50%;
}

.add-new-user-btn .black-btn {
  padding: 1rem 4rem;
}

@media only screen and (max-width: 767px) {
  .agreements {
    padding: 1.3rem;
  }

  .add-new-user {
    display: block;
  }

  .new-user {
    width: 100%;
    margin-bottom: 20px;
  }

  .add-new-user-btn .black-btn {
    width: 100%;
  }
}
