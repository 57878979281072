.product-table-container {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding-bottom: 34px;
}

.product-table-title {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e2e;
    margin:33px 0 23px 0;
}

.product-sec{
    overflow-x: scroll;
  }
  .product-sec-heading h2 {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2e2e2e;
    }
    
    .product-sec-heading {
      margin-bottom: 14px;
    }
    
    .product-sec-table .table-responsive {
      border-radius: 5px;
      border: solid 1px #e9e9e9;
      background-color: #ffffff;
    }
    
    .product-sec-table .table-responsive .table {
      margin: 0px;
    }
    
    .product-sec-table .table-responsive .table thead tr th {
      font-size: 10px;
      font-weight: 600;
      color: #2e2e2e;
      min-width: 60px;
    }
    
    .product-sec-table .table-responsive .table tbody tr td {
      font-size: 12px;
      font-weight: 500;
      color: #2e2e2e;
      min-width: 60px;
      vertical-align: middle;
    }
    
    .product-sec-table .table-responsive .table tbody tr td .label-status-paid {
      font-size: 12px;
      font-weight: 500;
      color: #28a745;
      text-align: center;
      padding: 5px 15px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #28a745;
    }
    
    .product-sec-table .table-responsive .table tbody tr td .status-unpaid {
      font-size: 12px;
      font-weight: 500;
      color: #dc3545;
      text-align: center;
      padding: 5px 15px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #dc3545;
    }
    
    .product-sec-table .table-responsive .table tbody tr .product-aling {
      vertical-align: top;
    }
    