.alert-overlay-background {
  background-color: rgba(0, 42, 76, 1)!important;
}

.alert-modal-dialog {
  max-width: 430px!important;
}

.alert-modal-container {
  padding: 6px 34px 34px 34px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}

.alert-modal-close-button {
  position: absolute;
  right: 27px;
  top: -36px;
  cursor: pointer;
}

.alert-modal-title {
  margin:60px auto 20px auto;
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #737373;
}

.alert-message {
  font-family: 'Montserrat';
  font-size: 12px;
  margin-bottom: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #737373;
}

.alert-box-button-container {
  display: flex;
  justify-content: space-around;
}
/* added for edit */
.alert-modal-title-edit {
  margin:60px auto 20px auto;
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #737373;
}

.alert-box-button-container-edit {
  margin:50px auto 20px auto;
  display: inline;
  justify-content: space-around;
}