.heading-disbursement{
  font-size: 14px;
  margin-top: 5px;
  color: #303641;
  }
  
  .para-disbursement{
    font-size: 11px;
    margin-top: 5px;
    color: #303641;
  }
  
  .num-disbursement{
    color: #303641;
    font-size: 20px;
    font-weight: bold;
  }