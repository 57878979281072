.financial-partner-container{
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding-bottom:33px;
}
.financial-partner-title{
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
  margin:35px 0 25px 0;
}
.paytm-razor-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}
.product-table-add-button-wrapper{
  position: relative;
}
.add-product-button{
  position: absolute;
  top:-10px;
  right:0px;
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 767px) {
}