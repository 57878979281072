.upload-doc {
  border-radius: 6px;
  background-color: #ffffff;
  padding: 20px 30px 24px 30px;
  max-width: 500px;
}

.upload-doc h1 {
  font-size: 12px;
  font-weight: 600;
  color: #002a4c;
  padding-bottom: 22px;
}

.upload-doc-input-container {
  margin-bottom: 20px;
}

.upload-doc .filter-select-value {
  width: auto;
}

.upload-doc-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.upload-doc-buttons button:first-child {
  margin-right: 47px;
}

.upload-doc-buttons button:last-child {
  margin-left: -23px;
}

.file-upload-btn {
  font-size: 12px !important;
  padding: 10px 14px !important;
  font-weight: 600;
}

.upload-doc .fliter-input-value {
  width: auto;
}

.upload-doc .fliter-input-value input[type=text]::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: #b9b9b9;
}

.upload-doc-file-input {
  border-radius: 6px;
  border: dashed 1px #d7d9de;
  background-color: #ffffff;
  height: 60px;
  color: transparent;
}

.upload-doc-file-input:focus {
  outline: none !important;
}

.upload-doc-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

/* .upload-doc-file-input::before {
  content: 'Browse file';  
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  color: #002a4c;
  border-bottom: 1px solid #002a4c;
} */

.upload-doc-text-container {
  position: relative;
}

.upload-doc-text {
  position: absolute;
  top: 49%;
  left: 30%;
  font-size: 11px;
  font-weight: 500;
  color: #737373;
}
.error-upload-document{
  font-size: 10px;
  color: red;
  font-family: 'Montserrat';
}

.upload-doc-browse-file {
  font-weight: 600;
  color: #002a4c;
  border-bottom: 1px solid #002a4c;
  cursor: pointer;
}

.upload-doc-file-type {
  font-weight: 600;
  color: #2e2e2e;

}

.uploaded-doc-message {
  margin-bottom: 13px;
}

.uploaded-doc-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #737373
}

.uploaded-doc-details li img {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .upload-doc-buttons button:first-child {
    margin-right: 0;
  }

  .upload-doc-buttons button:last-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .upload-doc-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .file-upload-btn {
    font-size: 10px !important;
    margin: 0 10px 10px 0;
  }

  .upload-doc-buttons button:first-child {
    margin-right: 10px;
  }

  .upload-doc-file-input {
    height: 80px;
  }

  .upload-doc-text {
    left: 25%;
  }
}

@media only screen and (max-width: 400px) {
  .upload-doc-text {
    left: 10%;
  }
}
