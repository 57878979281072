.block-dashboard-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px 10px 20px 10px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    position: relative;
    min-width: 300px;
    margin:10px auto;
    width: 43%;

}
.progress-bar-container-wrapper{
    width: 90%;
}
.right-arrow-block-dashboard {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    top: 50%;
    right: 0px;
    border-radius: 50%;
    transform: translate(15px, -50%);
    cursor: pointer;
}
.title-block-dashboard{
    text-align: center;
    margin-bottom: 10px!important;
}

.right-arrow-block-dashboard:hover {
    background-color: black;
}

.right-arrow-block-dashboard:hover::before {
    border-color: white;
}

.right-arrow-block-dashboard::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    transform: rotate( 45deg) translate(11px, 2px);
}

.left-arrow-block-dashboard {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    top: 50%;
    left: 0px;
    border-radius: 50%;
    transform: translate(-15px, -50%);
    cursor: pointer;
}

.left-arrow-block-dashboard::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    transform: rotate( -45deg) translate(1px, 14px);
}

.left-arrow-block-dashboard:hover {
    background-color: black;
}

.left-arrow-block-dashboard:hover::before {
    border-color: white;
}

@media screen and (max-width: 600px) {
    .block-dashboard-wrapper {
        width:95%;
    }
  }