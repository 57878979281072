.wallet-setting-container{
    border-radius: 4px;
    border: solid 1px #d7d9de;
    background-color: #ffffff;
    position: relative;
}
.wallet-setting-title{
    padding: 11px 13px 11px 16px;
    border-radius: 4px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #fbfbfb;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e2e;
}
.settings-wrapper{
    padding:0 13px 25px 13px;
}
.label-settings-wallet{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #737373;
}
.label-setting-value{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002a4c;
}
.label-value-wallet-wrapper{
    margin-top:25px;
}
.edit-wallet-button{
    position: absolute;
    top:13px;
    right:11px;
    cursor: pointer;
}