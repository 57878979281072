.page-loader-sec {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 42, 76, 0.3);
  position: absolute;
}

.loader-sec {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

.loader-img {
  position: absolute;
  text-align: center;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  width: 100px;
  margin: 0px auto;
  height: 100px;
  border-radius: 50%;
}

.loader-img img {
  vertical-align: middle;
  border-style: none;
  margin-top: 30px;
}

.loader-text {
  text-align: center;
  margin-top: -5rem;
}

.loader-content {
  position: relative;
}

.loader-text h1 {
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  font-size: 2.2rem;
  font-weight: 600;
  color: #ffffff;
  /* margin-top: 4rem; */
}

.page-loader .loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.page-loader .loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #d7d9de;
  border-right: 0.5em solid #d7d9de;
  border-bottom: 0.5em solid #d7d9de;
  border-left: 0.5em solid #002a4c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.black-bg-loader {
  background-color: #002a4c !important;
  color: #fff !important;
}

.table-loader {
  margin-top: 20px;
}

.table-loader-img {
  margin-bottom: 12px;
}

.table-loader-text h1 {
  font-size: 15px;
  font-weight: 500;
  color: #002a4c;
}
