.fliter-input-value .down-arrow-img {
    position: absolute;
    top: 41px;
    right: 10px;
}

.number-range-container {
    display: flex;
    justify-content: space-between;
    margin-top: -29px;
}

.down-arrow-img {
    position: absolute;
    top: 41px;
    right: 10px;
}

.number-range-container>input {
    margin-right: 5px;
}

.form-element-lable {
    margin: 20px 0 10px 0!important;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #737373;
}
.form-element-lable-toggle{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #737373;
}

.form-element-input-box {
    height: 42px!important;
    padding: 9px 12px 9px 13px!important;
    border-radius: 6px!important;
    border: solid 1px #dfdfdf!important;
    background-color: #ffffff!important;
    width: 100%!important;
    display: block!important;
    cursor: pointer;
    margin-top: 2px;
    font-size: 15px;
}

.form-element-input-box1 {
    height: 42px!important;
    padding: 9px 12px 9px 13px!important;
    border-radius: 6px!important;
    border: solid 1px #dfdfdf!important;
    background-color: #ffffff!important;
    width: 100%!important;
    display: block!important;
    cursor: pointer;
    margin-top: -21px;
    font-size: smaller;
}

.form-element-text-area {
    padding: 9px 12px 9px 13px!important;
    border-radius: 6px!important;
    border: solid 1px #dfdfdf!important;
    background-color: #ffffff!important;
}

.form-element-constant {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002a4c;
}

.w-full {
    width: 100%;
}

.form-element-input-box-file {
    height: 42px!important;
    padding: 9px 12px 9px 13px!important;
    border-radius: 6px!important;
    border: solid 1px #dfdfdf!important;
    background-color: #ffffff!important;
    width: 100%!important;
    display: block!important;
}

.form-element-input-box-file:after {
    padding: 10px 22px 10px 22px!important;
    top:2px!important;
    right:2px!important;
    height: 35px!important;
    border-radius: 4px!important;
    border: solid 1px #002a4c!important;
    background-color: #ffffff!important;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #002a4c!important;
}
.form-select-option{
    font-family: 'Montserrat';
    font-size: 13px;
    font-stretch: normal;
    line-height: 18px;
    color: #16181b;
}

.form-select-custom{
    font-family: 'Montserrat';
    font-size: 13px;
    font-stretch: normal;
    line-height: 18px;
    color: #16181b;
}
.error-msg-common{
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    line-height: 18px;
    color: #dc3545;
}