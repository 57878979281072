.error-container {
    padding: 13px 13px 17px 16px;
    border-radius: 6px;
    border: solid 1px #dc3545;
    background-color: #fffafb;
    display: flex;
}

.error-heading {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #dc3545;
    margin-bottom: 6px;
}

.error-msg {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #737373;
    margin-bottom: 8px;
}

.alert-container {
    padding: 13px 13px 17px 16px;
    border-radius: 6px;
    border: solid 1px #f2e2b5;
    background-color: #fff7de;
    display: flex;
}

.alert-heading {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f3cb5c;
    margin-bottom: 6px;
}

.alert-msg {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2e2e2e;
    margin-bottom: 8px;
}

.success-container {
    margin: 0px;
    padding: 10px 12px 8px;
    border-radius: 6px;
    border: solid 1px #28a745;
    background-color: #f8fff9;
    display: flex;
}
.alert-box-wrapper{
    padding:0!important;
}

.success-heading {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #28a745;
    margin-bottom: 6px;
}

.success-msg {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #737373;
}
svg{
    margin-right:17px;
}
.alert-dismissible>.close{
    font-size: 3em;
    opacity: .75;
}