.default-loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(0, 163, 255, 0.05);
  padding: 16px 20px;
  margin-bottom: 40px;
}
.default-loader span {
  cursor: pointer;
}
.default-loader-sec {
  display: flex;
  align-items: center;
}
.default-loader-sec p {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #2e2e2e;
}
.default-loader-sec-loader {
  width: 4em;
}
.default-loader-sec-loader .loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.default-loader-sec-loader .loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid #d8d8d8;
  border-right: 0.2em solid #d8d8d8;
  border-bottom: 0.2em solid #d8d8d8;
  border-left: 0.2em solid #00a3ff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin-right: 11px;
}

.num-defaults {
  color: #303641;
  font-size: 30px;
  font-weight: bold;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
