.delete-reason {
  border-radius: 6px;
  background-color: #ffffff;
  height: 214px;
}

.block-user {
  padding: 34px 25px 25px 25px;
}

.delete-reason h1,
.block-user h1 {
  font-size: 14px;
  font-weight: 600;
  color: #2e2e2e;
}

.block-user h1 {
  margin-bottom: 34px !important;
}

.delete-reason h1 {
  padding: 34px 25px;
}

.block-user-reason .custom-input label,
.delete-reason .custom-input label {
  font-size: 12px;
  font-weight: 500;
  color: #737373;
}

.block-user-reason .custom-input label {
  margin-bottom: 10px !important;
}

.block-user-reason .custom-input .form-control,
.delete-reason .custom-input .form-control {
  /* height: 123px; */
  border-radius: 6px;
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
}

.block-user-reason .custom-input .form-control {
  min-height: 123px !important;
}

.block-user-reason .custom-input {
  margin-bottom: 0 !important;
}

.delete-reason-button {
  float: right;
  margin: 0px 25px 25px 0px;
  margin-top: 40px;
}

.delete-reason-button label {
  margin-right: 20px;
}

.delete-reason-button .black-border-btn,
.delete-reason-button .cancel-btn {
  min-width: 100px;
}

.reasons {
  margin: 0 27px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.reasons label img {
  width: 100%;
}

.reasons label {
  width: 40px;
  height: 35px;
  margin-right: 16px;
}

.reasons span {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e2e;
}

.reasons .custom-input {
  padding: 0px 25px;
}

.delete-reason textarea {
  resize: inherit !important;
}

@media only screen and (max-width: 400px) {
  .reasons {
    align-items: flex-start;
  }

  .delete-reason-button .black-border-btn,
  .delete-reason-button .cancel-btn {
    min-width: 80px;
  }

  .block-user .delete-reason-button button {
    font-size: 13px;
    padding: 6px 8px;
    min-width: 80 !important;
  }


  .block-user {
    padding: 34px 20px 25px 20px;
  }
}
