.wallet-modal-overlay-background {
    background-color: rgba(0, 42, 76, 1)!important;
}

.modal .wallet-modal-dialoag {
    max-width: 600px!important;
    margin-top: 100px!important;
    margin-bottom: 50px!important;
    height: auto!important;
}

.wallet-modal-container {
    padding: 30px 34px 30px 34px;
    border-radius: 6px;
    background-color: #ffffff;
    position: relative;
}

.wallet-modal-close-button {
    position: absolute;
    right: 27px;
    top: -36px;
    cursor: pointer;
}

.wallet-modal-title {
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002a4c;
}

.wallet-modal-button-container {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
}