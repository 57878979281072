.user-details-basic-extra-wrapper{
    display: grid;
    grid-template-columns:1fr 1fr;
    margin-bottom: 30px;
}

@media (max-width:576px) {
    .user-details-basic-extra-wrapper{
        flex-direction: column;
        justify-content:start;
        margin-bottom: 30px;
        display: flex;
    }
}