
.field-name {
    font-family: 'Montserrat';
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #737373;
}

.field-value {
    font-family: 'Karla';
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #448AFA;
    margin-top:13px;
}
.field-value-wrapper{
    margin-top:24px;
    position: relative;
}
.edit-button-wrapper{
    position: absolute;
    top: 0;
    right: 100px;
}
.select-form-wrapper{
    width:40%;
}

.form-control1{
    height: 48px;
    width: 75%;
    padding: revert;
    font-size: 16px;
}
